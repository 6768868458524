import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'
import Link from '../../atoms/Link'

import { HeroBannerType } from './types'

export const Hero = styled.article<{
  $isGeneric?: boolean
  $type: HeroBannerType
}>`
  display: flex;
  width: 100%;
  ${({ $isGeneric }) =>
    $isGeneric &&
    css`
      & ${Main} {
        border-end-end-radius: 0;
        border-end-start-radius: 0;
      }
      margin-bottom: -4rem;
    `}

  ${({ $type }) =>
    $type === HeroBannerType.WithContent &&
    css`
      padding: 0 1.6rem;

      @media ${mq(sizes.tablet)} {
        padding: 0;
      }
    `}
`

const mainStyles = css`
  border-radius: 2rem;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`
export const Main = styled.div<{ $type: HeroBannerType }>`
  ${mainStyles}
  ${({ $type }) =>
    $type === HeroBannerType.WithContent &&
    css`
      border-radius: 2rem;

      @media ${mq(sizes.tablet)} {
        border-radius: 4rem;
      }
    `}
`
export const MainLink = styled(Link)`
  ${mainStyles}
`

export const BannerContainer = styled.div<{ $logo: boolean }>`
  display: flex;
  min-height: 25rem;
  & > * {
    flex: 1;
  }
  @media ${mq(sizes.tablet)} {
    min-height: ${({ $logo }) => ($logo ? '51rem' : '32rem')};
  }
`

const cardStyles = css`
  position: relative;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const Card = styled.div`
  ${cardStyles}
`
export const CardLink = styled(Link)`
  ${cardStyles}
`

export const Bezel = styled.svg<{ $type: HeroBannerType }>`
  z-index: 1;
  position: absolute;
  top: -0.9rem;
  width: 100%;
  height: 1rem;
  & path {
    fill: ${({ theme }) => theme.colors.pureWhite};
  }

  ${({ $type }) =>
    $type === HeroBannerType.WithContent &&
    css`
      top: -1.9rem;
      height: 2rem;

      @media ${mq(sizes.tablet)} {
        top: -2.9rem;
        height: 3rem;
      }
    `}
`

export const LogoContainer = styled(Link)`
  position: absolute;
  width: 18rem;
  height: 18rem;
  padding-top: 2.4rem;
  top: -5.8rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1;

  @media ${mq(sizes.tablet)} {
    width: 50rem;
    height: 40rem;
    top: -14rem;
  }
`

export const Logo = styled(Picture)`
  width: 8rem;
  height: 8rem;

  @media ${mq(sizes.tablet)} {
    width: 12rem;
    height: 12rem;
  }

  & img {
    height: auto;
    object-fit: contain;
  }
`

export const LogoSpacer = styled.div`
  width: 100%;
  height: 3rem;

  @media ${mq(sizes.tablet)} {
    display: none;
  }
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1.5rem 3rem 1.5rem;
  z-index: 2;

  @media ${mq(sizes.tablet)} {
    padding: 3rem 2rem 3rem 2rem;
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH4};
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;
  width: 100%;
  margin: 0 0 2rem 0;

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH3};
  }
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.textMedium};
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.textLarge};
  }
`

export const Box = styled.div`
  @media ${mq(sizes.tablet)} {
    padding: 0 3rem;
  }
`
