import React, { FC } from 'react'
import { LinkProps } from 'src/components/atoms/Link'
import { PictureProps } from 'src/components/atoms/Picture'

import Banner, { BannerProps } from '../Banner'
import ListTagLinks, { ListTagLinksProps } from '../ListTagLinks'

import * as SC from './styled'
import { HeroBannerType } from './types'

export type HeroBannerProps = MinimalSeoBlock & {
  className?: string
  bannerProps: BannerProps
  title?: string
  text?: string
  brandLogoProps?: PictureProps
  brandLogoLinkProps?: LinkProps
  listTagLinksProps?: ListTagLinksProps
  logo?: boolean
  isGeneric?: boolean
  content?: React.ReactNode
  type?: HeroBannerType
}

const HeroBanner: FC<HeroBannerProps> = ({
  className,
  htmlTag,
  bannerProps,
  title,
  text,
  brandLogoProps,
  brandLogoLinkProps,
  listTagLinksProps,
  isGeneric,
  content,
  type = HeroBannerType.Default,
}) => {
  return (
    <SC.Hero
      className={className}
      as={htmlTag}
      $isGeneric={isGeneric}
      $type={type}
    >
      <SC.Main $type={type}>
        <SC.BannerContainer $logo={brandLogoProps ? true : false}>
          <Banner {...bannerProps} />
        </SC.BannerContainer>
        <SC.Card>
          {type === HeroBannerType.Default ? (
            <SC.Bezel
              viewBox="0 0 335 11"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              $type={type}
            >
              <path d="M0 0.375V10.375H168.303C99.7883 10.3705 38.8536 6.45373 0 0.375ZM168.537 10.375H335V0.658797C296.078 6.57536 235.98 10.3706 168.537 10.375Z" />
            </SC.Bezel>
          ) : (
            <SC.Bezel
              viewBox="0 0 1280 40"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              $type={type}
            >
              <path d="M0 0v40h1280V1.136C1131.2 24.815 901.388 40 643.516 40 381.546 40 148.54 24.33 0 0Z" />
            </SC.Bezel>
          )}

          {brandLogoProps && (
            <>
              <SC.LogoContainer {...brandLogoLinkProps}>
                <SC.Logo {...brandLogoProps} />
              </SC.LogoContainer>
              <SC.LogoSpacer />
            </>
          )}
          <SC.Content>
            {listTagLinksProps && <ListTagLinks {...listTagLinksProps} />}
            {title && <SC.Title>{title}</SC.Title>}
            {text && <SC.Text dangerouslySetInnerHTML={{ __html: text }} />}
            {content && <SC.Box>{content}</SC.Box>}
          </SC.Content>
        </SC.Card>
      </SC.Main>
    </SC.Hero>
  )
}

export default HeroBanner
